@layer utilities {
   /* Going over the limitations of formie class settings */
   .formie {
      --fui-alert-font-size: 20px;
      --fui-alert-line-height: 1.5;
      --fui-alert-padding: calc(var(--spacing) * 0.25);

      input, textarea {
         @apply rounded-none;
      }
      /* Checkbox links */
      input[type="checkbox"],
      input[type="radio"] {
         & ~ label a {
            @apply underline;
         }
      }
      [data-field-type="file-upload"] {
         @apply my-4;

         label {
            @apply m-0 underline text-lg truncate;
         }
         input {
            @apply w-1 h-1 opacity-0 absolute;
         }
      }

      .fui-alert {
         em {
            @apply inline-block mt-sixth text-sm;
         }
      }
   }

   #newsletter {
      .fui-alert {
         @apply bg-white;
      }
   }
}
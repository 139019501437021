@layer utilities {
   /* Mobile and/or touch */
   @media (hover: none) or (max-width: 767px) {
      .fanboy {
         display: none;
      }
      .fanboy-cursor-horizontal-scroll {
         @apply cursor-grab;
      }
   }
   /* Desktop and/or mouse */
   @media (hover: hover) {
      .fanboy {
         @apply fixed top-0 left-0 translate-x-[50vw] translate-y-[50vh] rounded-full z-40 pointer-events-none opacity-0;
         transition: transform .1s ease-out, opacity 1s;
         will-change: transform;

         /* Activated state */
         &.fanboy-is-stalking {
            @apply opacity-75;
         }
         /* Horizontal scroll cursor */
         &.fanboy-cursor-horizontal-scroll {
            @apply opacity-100;
         }
         /* Page transition */
         .is-animating &{
            @apply opacity-100;
         }
         /* Form modal background */
         [data-fn-body-modal-is-open] &{
            @apply opacity-100 z-[39] duration-[.1s,.3s];
         }
      }
      .fanboy-shape {
         @apply w-[42px] h-[42px] flex items-center justify-center rounded-full shadow-yellow fill-dark pointer-events-none -translate-x-1/2 -translate-y-1/2;
         transition: transform .6s, box-shadow .6s, opacity .5s, width .6s, height .6s;
         box-shadow: inset 0 0 0 1.75rem var(--tw-shadow-color);
         animation: morph 3s linear infinite alternate;

         /* Section colors */
         .bg-yellow &, .fanboy-is-white & {
            @apply shadow-white;
         }
         .fanboy-is-dark &{
            @apply shadow-dark;
         }
         .fanboy-is-yellow &{
            @apply shadow-yellow;
         }
         .fanboy-is-gray &{
            @apply shadow-gray;
         }

         /* Link hover state */
         .fanboy-is-hovering & {
            @apply scale-[2] opacity-75;
            box-shadow: inset 0 0 1rem var(--tw-shadow-color);
         }
         .fanboy-cursor-horizontal-scroll & {
            @apply scale-[3] opacity-100;

            svg {
               @apply scale-100 opacity-100;
            }
         }
         .fanboy-cursor-none & {
            @apply scale-[0] opacity-0;
         }

         /* Page transition */
         .is-animating & {
            @apply w-[175vmax] h-[175vmax] scale-100 opacity-100;
            box-shadow: inset 0 0 100vmax 200vmax var(--tw-shadow-color);
         }
         /* Form modal background */
         [data-fn-body-modal-is-open] &{
            @apply w-[200vmax] h-[200vmax] scale-100 opacity-100;
            transition: transform .6s, box-shadow .3s, opacity .3s, width .6s, height .6s;
            box-shadow: inset 0 0 100vmax 200vmax var(--tw-shadow-color);
         }

         /* Horizontal scroll cursor */
         svg {
            @apply w-[32px] h-[9px] scale-x-0 opacity-0 transition;
         }
      }

      @keyframes morph{
         0% {
            border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%
         }
         25% {
            border-radius: 43% 67% 46% 74% / 69% 52% 48% 51%
         }
         50% {
            border-radius: 62% 48% 53% 37% / 45% 66% 44% 65%
         }
         75% {
            border-radius: 63% 37% 47% 53% / 40% 60% 49% 50%
         }
         100% {
            border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%
         }
      }
   }
}
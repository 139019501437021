/*
--------------------------------------------------------------------------
  RICH TEXT STYLES
  Styles for the client CMS editor field
  IMPORTANT - add the wysiwyg class on your template div
--------------------------------------------------------------------------
*/

@layer utilities {
	.wysiwyg {
		&::after {
			display: block;
			clear: both;
			content: "";
		}

		h2 {
			margin: 1.5em 0 1em;
			font-size: 2rem;
		}

		h3 {
			margin: 1.5em 0 0.6em;
			font-size: 1.6rem;
		}

		h4 {
			margin: 1.5em 0 0.6em;
			font-size: 1.4rem;
		}

		/* remove margins on first and last element to avoid double gap with other elements */
		> *:first-child {
			margin-top: 0;
		}

		> *:last-child {
			margin-bottom: 0;
		}

		p {
			overflow: hidden;
			margin-top: 0;
			margin-bottom: 2rem;
		}

		li p {
			margin-bottom: 1rem;
		}

		iframe {
			max-width: 100%;
		}

		/*
      * LINKS
      */

		a {
			@apply underline;
		}

		/*
      * TABLES
      */

		table {
			display: table;
			width: 100%;
			height: 100%;
			margin: 4rem auto 4rem 0;
			border-collapse: collapse;
			border-spacing: 0;

			@screen sm {
				width: 100%;
				overflow-x: auto;
			}

			td,
			th {
				min-width: 2em;
				padding: 1em;
				@apply border;
				@apply border-slate-500;

				> *:last-child {
					margin-bottom: 0;
				}
			}

			th {
				@apply bg-slate-400;
				font-size: 1.06em;
			}
		}

		/*
      * MARK
      */

		mark {
			@apply bg-yellow-300;
		}

		/*
      * IMAGES
      */

		img,
		svg {
			max-width: 100%;
			height: auto;
		}

		/*
      * Blockquote
      */

		blockquote {
			position: relative;
			margin-bottom: 2rem;
			padding: 1rem 2em;
			font-style: italic;

			&::before {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 4px;
				height: 100%;
				@apply bg-yellow;
				content: "";
			}
		}

		/*
      * Unordered list
      */

		> ul {
			margin-bottom: 2rem;

			> li {
				position: relative;
				margin: 0 0 0.5em;
				padding-left: 1.5em;
				list-style: none;

				&::before {
					position: absolute;
					left: 0;
               width: 1.25em;
               text-align: center;
               font-size: 1.25em;
               line-height: 1.45em;
					content: "◦";
				}
			}
		}

		> ul ul {
			margin: 1em 0;

			li {
				position: relative;
				padding-left: 1em;
				list-style: none;

				&::before {
					position: absolute;
					left: 0;
					margin-right: 1em;
					content: "—";
				}
			}
		}

		> ul ul ul {
			margin: 0.5em 0;
		}

		/*
      * OL list with 3 levels
      * Tous les units sont en EM pour faciliter la coherence entre mobile et desktop
      */

		> ol {
			counter-reset: counter1;
			margin-bottom: 2rem;
			padding-top: 1rem;

			> li {
				position: relative;
				margin: 0 0 1.2em;
				padding-left: 2em;
				list-style: none;
				counter-increment: counter1;


				&::before {
               @apply font-semibold font-title;
					content: counter(counter1) ".";
					position: absolute;
					left: 0;
               width: 2em;
				}
			}
		}

		> ol ol {
			counter-reset: counter2;
			margin: 1em;

			li {
            position: relative;
				margin: 0 0 1.2em;
				padding-left: 2.5em;
				list-style: none;
				counter-increment: counter2;

				&::before {
               @apply font-semibold font-title;
					content: counter(counter1) "." counter(counter2);
					position: absolute;
					left: 0;
               width: 2.5em;
            }
			}
		}

		> ol ol ol {
			counter-reset: counter3;
			margin: 0.5em 0;

         li {
            position: relative;
				margin: 0 0 1.2em;
				padding-left: 2.5em;
				list-style: none;
				counter-increment: counter3;

				&::before {
               @apply font-semibold font-title;
					content: counter(counter1) "." counter(counter2) "." counter(counter3);
					position: absolute;
					left: 0;
               width: 2.5em;
            }
         }
		}
	}

   em {
      @apply font-title;
   }
}

@layer utilities {
	.lazy-fit {
		@apply relative overflow-hidden;

		img {
			@apply absolute inset-x-0 inset-y-0 w-full h-full text-gray-300 object-cover object-center;

         .has-scroll-smooth & {
            @apply -inset-y-8 h-[calc(100%+4rem)];
         }
		}
	}

	/*
      Use lazy-img for a client side uploaded image > height and width attribute must be setted on the img tag
   */
	.lazy-img {
		@apply relative overflow-hidden;

		img {
         @apply max-w-full h-auto text-gray object-cover object-center;
		}
	}
}

@layer utilities {
   .fade {
      @apply opacity-0 is-inview:opacity-100 transition-opacity duration-600;
   }
   .fade-up {
      @apply translate-y-half opacity-0 is-inview:opacity-100 is-inview:transform-none transition duration-600;
   }
   .fade-down {
      @apply -translate-y-half opacity-0 is-inview:opacity-100 is-inview:transform-none transition duration-600;
   }
   .fade-left {
      @apply translate-x-threequarter opacity-0 is-inview:opacity-100 is-inview:transform-none transition duration-600;
   }
   .fade-right {
      @apply -translate-x-threequarter opacity-0 is-inview:opacity-100 is-inview:transform-none transition duration-600;
   }
   .fade-cancel {
      @apply transform-none opacity-100
   }

   /* Try to fix some glitches on Chromium */
   [data-scroll-container], [data-scroll-section] { 
      transform-style: preserve-3d; 
      will-change: transform; 
   }
   .has-scroll-smooth [data-scroll-container] {
      transform: perspective(1px);
   }
   html.has-scroll-smooth { 
      overflow: hidden;  /* fix */ 
      position: fixed; 
      left: 0; 
      right: 0; 
      top: 0; 
      bottom: 0;  
   }
}

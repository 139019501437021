/* base layer */
@import "./base/root.css";
@import "./base/titles.css";
@import "./base/container.css";
@import "./base/sections.css";

/* utilities layer */
@import "./utilities/scroll.css";
@import "./utilities/cursor.css";
@import "./utilities/lazyload.css";
@import "./utilities/wysiwyg.css";
@import "./utilities/height.css";
@import "./utilities/formie.css";
@import "./utilities/swup.css";

/* plugins */
@import "locomotive-scroll/dist/locomotive-scroll.css";

/* Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.transition-swup {
		transition: opacity .25s ease-in;
		opacity: 1;
	}
	html.is-animating {
		.transition-swup {
			opacity: 0;
			pointer-events: none;
		}
	}
}
